import { graphql } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import ProductFinderShortcut from "../molecules/ProductFinderShortcut"

type DatoProductFinderShortcutProps = Queries.DatoProductFinderShortcutFragment

const DatoProductFinderShortcut = (props: DatoProductFinderShortcutProps) => {
  // console.debug("Rendering DatoProductFinderShortcut", props)
  const { image, ...rest } = props
  const imgNode = image?.gatsbyImageData ? (
    <GatsbyImage image={image.gatsbyImageData as unknown as IGatsbyImageData} alt={image.alt || ""} />
  ) : null
  return <ProductFinderShortcut {...rest} image={imgNode} />
}

export default DatoProductFinderShortcut

export const query = graphql`
  fragment DatoProductFinderShortcut on DatoCmsProductFinderShortcut {
    image {
      alt
      gatsbyImageData(width: 200, height: 200)
    }
    searchQuery
    description
    title
  }
`
