import React from "react"
import useRoutePath from "../../hooks/useRoutePath"
import useWebsite from "../../hooks/useWebsite"
import Heading from "../atoms/Heading"
import * as styles from "./ProductFinderShortcut.module.scss"

type ProductFinderShortcutProps = {
  searchQuery?: string | null
  image: React.ReactNode
  title?: string | null
  description?: string | null
}

const ProductFinderShortcut = (props: ProductFinderShortcutProps) => {
  //console.log('Rendering ProductFinderShortcut', props)

  // tuotehakusivu
  const getRoutePath = useRoutePath()
  const productPagePath = getRoutePath(useWebsite().productRootRoute?.originalId)

  return (
    <div className={styles.shortcut}>
      <a href={`${productPagePath}${props.searchQuery || ""}`}>
        {props.image}
        <Heading level="h4" as="div">
          {props.title}
        </Heading>
        <p>{props.description}</p>
      </a>
    </div>
  )
}

export default ProductFinderShortcut
