import React, { useContext } from "react"
import { PageContext } from "../../../context/PageContext"
import { ProductSearchContext } from "../../../context/ProductSearchContext"
import useDatoMicrocopy from "../../../hooks/useDatoMicrocopy"
//import * as styles from "./ProductSearchField.module.scss"

// type ProductSearchFieldProps = {}

const ProductSearchField = (/*props: ProductSearchFieldProps*/) => {
  //console.log('Rendering ProductSearchField', props)
  const [search, setSearch] = useContext(ProductSearchContext)
  const { locale } = useContext(PageContext)
  const t = useDatoMicrocopy("productFinder", locale)

  const label = t("searchFieldLabel", "Search")

  let changeTimeout: NodeJS.Timeout
  const changeHandler = (val: string) => {
    clearTimeout(changeTimeout)
    changeTimeout = setTimeout(() => {
      if (!val) {
        setSearch({ options: {}, active_filters: [] })
      } else {
        //setSearch({ options: {}, active_filters: [searchFromField + ":" + query] })
        setSearch({
          options: {},
          active_filters: [{ label, query: `q:${val}` }],
        })
      }
    }, 500)
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    changeHandler(e.currentTarget.value)
    e.currentTarget.blur()
  }

  return (
    <input
      type="text"
      placeholder={label}
      onChange={e => changeHandler(e.currentTarget.value)}
      style={{ width: "100%" }}
      onKeyDown={e => e.key == "Enter" && onEnterPress(e)}
    />
  )
}

export default ProductSearchField
