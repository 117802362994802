import React, { ReactNode } from "react"
// import s from "./Heading.module.scss"

type HeadingProps = {
  level: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
  children: ReactNode
  /** Ovewrite used tag to create non-sematic heading like element */
  as?: "span" | "div"
}

/**
 * Consistent look&feel headings with support to non-semantic
 * headings using `<span>` or `<div>` as element.
 */
const Heading = (props: HeadingProps) => {
  const Tag = props.as ? props.as : props.level
  return <Tag className={props.level}>{props.children}</Tag>
}

export default Heading
