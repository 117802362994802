import { graphql } from "gatsby"
import React, { useContext } from "react"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import { useSwipeable } from "react-swipeable"
import { PageContext } from "../../context/PageContext"
import ProductSearchContextProvider from "../../context/ProductSearchContext"
import useDatoMicrocopy from "../../hooks/useDatoMicrocopy"
import { isPimUnitSystem } from "../../utils"
import Button from "../atoms/Button"
import DatoProductFinderShortcut from "../dato/DatoProductFinderShortcut"
import ProductUserAreaInfo from "../molecules/ProductUserAreaInfo"
import ProductSearchCategories from "../organisms/productSearch/ProductSearchCategories"
import ProductSearchField from "../organisms/productSearch/ProductSearchField"
import ProductSearchFilters from "../organisms/productSearch/ProductSearchFilters"
import ProductSearchResults from "../organisms/productSearch/ProductSearchResults"
import * as styles from "./ProductSearchPage.module.scss"

const ProductSearchPage = (props: Queries.DatoProductSearchPageFragment) => {
  //console.log('Rendering ProductSearchPage', props)
  const { locale } = useContext(PageContext)
  const t = useDatoMicrocopy("common", locale)
  const [sidebarVisible, setSidebarVisible] = React.useState(true)

  function toggleSidebar() {
    setSidebarVisible(!sidebarVisible)
  }

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => setSidebarVisible(false),
    onSwipedRight: () => setSidebarVisible(true),
    preventScrollOnSwipe: true,
    trackMouse: false,
  })

  return (
    <ProductSearchContextProvider>
      <div className={`${styles.container} ${sidebarVisible ? styles.visible : styles.hidden}`} {...swipeHandlers}>
        <div className={`${styles.sidebar}`}>
          <div className={styles.sticky}>
            <div>
              <ProductSearchField />
              <ProductSearchCategories />
              <ProductSearchFilters units={isPimUnitSystem(props.units) ? props.units : "metric"} />
            </div>
          </div>
          <button
            className={styles.toggleButton}
            onClick={toggleSidebar}
            aria-label={sidebarVisible ? t("close", "Close") : t("open", "Open")}>
            {sidebarVisible ? <IoIosArrowBack /> : <IoIosArrowForward />}
          </button>
        </div>
        <div className={styles.content}>
          <h1>{props.title}</h1>
          <ProductUserAreaInfo locale={locale} />
          <ProductSearchResults>
            <p className="lead">{props.text}</p>

            <div className={styles.controls}>
              {/* Näytä uutuudet -nappi */}
              <Button
                label={t("buttonShowNewProducts", "New products", "productFinder")}
                priority="primary"
                onClick={() =>
                  // HUOM! Katso tuotekortin "Uutuus" -labelin säännöt: molecules/ProductList.tsx
                  (location.search = `?search=${[
                    `available_from_date:gte:${Date.now() - 1000 * 60 * 60 * 24 * 360}`,
                    "product_life_cycle_status:or:s3_maintenance",
                    "product_life_cycle_status:or:s4_rampdown",
                  ].join(";")}`)
                }
              />

              {/* Näytä poistuneet tuotteet -nappi */}
              <Button
                label={t("buttonShowDiscontinuedProducts", "Discontinued products", "productFinder")}
                onClick={() => (location.search = "?search=product_life_cycle_status:s5_discontinued")}
              />
            </div>

            {props.shortcuts?.map((list, i) => {
              if (!list) return // Dato source genereted type null check, remove when possible
              return (
                <div key={i} className={styles.shortcuts}>
                  <h2>{list.title}</h2>
                  {list.productFinderShortcuts?.map(productShortcut => {
                    return (
                      productShortcut && <DatoProductFinderShortcut key={productShortcut.id} {...productShortcut} />
                    )
                  })}
                </div>
              )
            })}
          </ProductSearchResults>
        </div>
      </div>
    </ProductSearchContextProvider>
  )
}

export default ProductSearchPage

export const query = graphql`
  fragment DatoProductSearchPage on DatoCmsProductSearchPage {
    title
    text
    units
    shortcuts {
      title
      productFinderShortcuts {
        id
        ...DatoProductFinderShortcut
      }
    }
  }
`
